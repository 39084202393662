import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable", "rotateable"];
  static values = { activated: Boolean };

  connect() {
    this.toggleClass = this.data.get("class") || "hidden";
  }

  toggle(event) {
    event.preventDefault();

    this.activatedValue = !this.activatedValue;
  }

  deactivate(event) {
    if (event.target.type !== "radio") event.preventDefault();

    this.activatedValue = false;
  }

  activate(event) {
    if (event.target.type !== "radio") event.preventDefault();

    this.activatedValue = true;
  }

  activatedValueChanged() {
    if (!this.toggleClass) {
      return;
    }

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(this.toggleClass);
    });
  }

  toggleAndRotate(event) {
    this.toggle(event);
    this.rotateableTargets.forEach((target) => {
      if (target.classList.contains("rotate-90")) {
        target.classList.remove("rotate-90");
        target.classList.add("rotate-[270deg]");
      } else {
        target.classList.remove("rotate-[270deg]");
        target.classList.add("rotate-90");
      }
    });
  }
}
